<template>
<AppLayout>
    <template v-slot:appContent>
        <div>
            <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
                <h4 class="">Appointment cost estimation</h4>
                <div>
                    <router-link :to="{...previousRoute}">
                        <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
                    </router-link>
                </div>
            </div>

            <div class="card">
                <div class="card-body">

                    <div class="row ">
                        <div class="col-12 col-md-12">
                            <div class="form-group">
                                <label>Address</label>
                                <vue-google-autocomplete :enable-geolocation="true" ref="address" :country="['au']" id="map" classname="form-control" placeholder="Please type full address." v-on:placechanged="googleMapApiResponseData" />
                            </div>
                        </div>
                    </div>
                    <div class="pb-3" v-if="this.street || this.suburb || this.selectedState?.name || this.post_code" >
                        <p class="text-danger">Please modify the following address fields, if there anything wrong.</p>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label>Street Address</label>
                                    <input v-model="street" type="text" class="form-control" placeholder="Enter street address" />

                                </div>
                            </div>
                            <div class="col-12 col-lg-3 col-md-6 ">
                                <div class="form-group">
                                    <label>Suburbs/ Territory</label>
                                    <input v-model="suburb" type="text" class="form-control" placeholder="Enter suburbs/ territory" />

                                </div>
                            </div>
                            <div class="col-12 col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label>State</label>
                                    <VueMultiselect v-model="selectedState" class="" :options="state" :close-on-select="true" placeholder="Select state" label="name" track-by="value" :show-labels="false" />

                                </div>
                            </div>
                            <div class="col-12 col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label>Post Code</label>
                                    <input v-model="post_code" type="text" class="form-control" placeholder="Enter code" />

                                </div>
                            </div>
                            <div class="col-12 col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label>Country</label>
                                    <input v-model="country" type="text" class="form-control" readonly />

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-6 col-lg-2 col-md-3">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Service preference</label>
                                    <VueMultiselect v-model="selectedServicePreference" class="multiselect-blue" :options="servicePreferences" :close-on-select="true" :allow-empty="false" placeholder="Select service preference" label="name" track-by="value" :show-labels="false" />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-2 col-md-3">
                            <div class="form-group">
                                <label>Select Type</label>
                                <VueMultiselect :allow-empty="false" v-model="selectedServiceType" class="" :options="serviceTypes" :close-on-select="true" placeholder="Select service type" label="name" track-by="value" :show-labels="false" />
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-lg-4 col-md-6">
                            <div class="form-group">
                                <label>Select Service</label>
                                <VueMultiselect :allow-empty="false" v-model="selectedService" class="" :options="services" :close-on-select="true" placeholder="Select service" label="name" track-by="id" :show-labels="false" />
                            </div>
                        </div>
                      
                        <div class="col-12 col-sm-6 col-lg-2 col-md-6">
                            <div class="form-group">
                                <label>Select Date</label>
                                <div class="d-flex align-items-center date-picker-wrapper">
                                    <div class="w-100 ">
                                        <DatePicker v-model="selectedDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}" :min-date='new Date()' :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                            <template v-slot="{ inputValue ,togglePopover  }">
                                                <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                                                    <i class='bx bx-calendar mr-1'></i>
                                                    <input class="form-control" :value="inputValue" style="cursor: pointer;" />
                                                </div>
                                            </template>
                                        </DatePicker>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-2 col-md-6">
                            <div class="form-group">
                                <label>Time <span class="text-primary">{{technicianAvailableTimeSlots?.message ? '(' : '' }} {{ technicianAvailableTimeSlots?.message ?? ''}} {{technicianAvailableTimeSlots?.message ? ')' : '' }}</span></label>
                                <VueMultiselect :allow-empty="false" v-model="selectedTime" class="" :options="technicianAvailableTimeSlots?.slots ?? []" :close-on-select="true" placeholder="Select time" label="name" track-by="value" :show-labels="false" />
                            </div>
                        </div>
                     

                    </div>
                    <h5 class="py-1">Discounts</h5>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-lg-3 col-md-6">
                            <div class="form-group">
                                                <label>Select parking type</label>
                                                <VueMultiselect :allow-empty="false" v-model="selectedParkingType" class="" :options="parkingTypes" :close-on-select="true" placeholder="Select parking type" label="name" track-by="value" :show-labels="false" />
                                </div>
                        </div>
                        <div class="col-12 col-sm-12 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label>Coupon code </label>
                                <input v-model="couponCode" type="text" class="form-control" placeholder="Code" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-lg-3 col-md-6 d-flex justify-content-center">

                            <div class="form-group">
                                <div class="controls">
                                    <label>Add other discount</label>
                                    <div style="margin-top: .5rem">
                                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                            <div>
                                                <input v-model="customDiscount" type="checkbox" class="custom-control-input" id="customDiscount">
                                                <label class="custom-control-label mr-1" for="customDiscount"></label>
                                            </div>
                                            <span class="font-medium-1">{{ customDiscount ? "Yes" : "No" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="customDiscount" class="col-12 col-sm-12 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label>Discount type</label>
                                <VueMultiselect :allow-empty="false" v-model="selectedDiscountType" class="" :options="discountTypes" :close-on-select="true" placeholder="Select type" label="name" track-by="value" :show-labels="false" />
                            </div>
                        </div>
                        <div v-if="customDiscount && (selectedDiscountType?.name)" class="col-12 col-sm-12 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label>Discount Amount </label>
                                <input v-model="customDiscountAmount" type="number" class="form-control" placeholder="Amount" />
                            </div>
                        </div>
                    </div>

                    <div class="row mb-4">
                        <div class="col-12 ">
                            <div class="d-flex justify-content-end">
                                <button :disabled="!isCalculateButtonActive" @click="preAppointment()" type="button" class="btn btn-primary update-todo px-4">Calculate
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade " id="costDetailsModal" tabindex="-1" role="dialog" aria-labelledby="costDetailsModal">
            <div class="modal-dialog modal-dialog-scrollable modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header bg-primary border-0">
                        <h4 class="text-white">Details</h4>
                        <button type="button" class="close text-primary" data-dismiss="modal">
                            <i class="bx bx-x"></i>
                        </button>
                    </div>

                    <div class="modal-body text-center">
                        <div class="appointmentSuccessfulWrapper">
                            <div class="row">
                                <div class="col-7 text-left text-bold-700"><p class="">Service:</p></div>
                                <div class="col-5 text-right text-bold-700"><p class="">${{parseFloat(preAppointmentResponse.service_price/100).toFixed(2)}}</p></div>
                            </div>

                            <div class="row" v-if="preAppointmentResponse.timebase_surcharge.applied_status">
                                <div class="col-7 text-left">
                                    <p class="text-bold-400">Time-base surcharge:</p>
                                </div>
                                <div class="col-5 text-right">
                                    <p>${{ parseFloat(preAppointmentResponse.timebase_surcharge.amount /100 ).toFixed(2) }} </p>
                                </div>
                            </div>
                            <div class="row" v-if="preAppointmentResponse.weekend_surcharge.applied_status">
                                <div class="col-7 text-left">
                                    <p class="text-bold-400">Weekend Surcharge:</p>
                                </div>
                                <div class="col-5 text-right">
                                    <p class="">${{ parseFloat(preAppointmentResponse.weekend_surcharge.amount /100 ).toFixed(2) }}</p>
                                </div>
                            </div>
                            <div class="row" v-if="preAppointmentResponse.holiday_surcharge.applied_status">
                                <div class="col-7 text-left">
                                    <p class="text-bold-400">Holiday Surcharge:</p>
                                </div>
                                <div class="col-5 text-right">
                                    <p class="">${{ parseFloat(preAppointmentResponse.holiday_surcharge.amount /100).toFixed(2) }}</p>
                                </div>
                            </div>
                            <div class="row" v-if="preAppointmentResponse.distance_surcharge.applied_status">
                                <div class="col-7 text-left">
                                    <p class="text-bold-400">Distance Surcharge ({{preAppointmentResponse.distance_surcharge.distance_value_in_km}}) : </p>
                                </div>
                                <div class="col-5 text-right">
                                    <p class="">${{parseFloat(preAppointmentResponse.distance_surcharge.amount /100 ).toFixed(2)}} </p>
                                </div>
                            </div>
                            <div class="row" v-if="preAppointmentResponse.same_day_surcharge.applied_status">
                                <div class="col-7 text-left">
                                    <p class="text-bold-400">Sameday Fee:</p>
                                </div>
                                <div class="col-5 text-right">
                                    <p class="">${{ parseFloat(preAppointmentResponse.same_day_surcharge.amount /100 ).toFixed(2) }} </p>
                                </div>
                            </div>
                            <hr v-if="parseFloat(preAppointmentResponse.total_surcharge_amount / 100).toFixed(2) > 0">
                            <div class="row" v-if="parseFloat(preAppointmentResponse.total_surcharge_amount / 100).toFixed(2) > 0">
                                <div class="col-7 text-left">
                                    <p class="text-bold-700">Total Surcharge:</p>
                                </div>
                                <div class="col-5 text-right">
                                    <p class="text-bold-700">${{ parseFloat(preAppointmentResponse.total_surcharge_amount / 100).toFixed(2) }} </p>
                                </div>
                            </div>

                            <hr>
                            <div class="row">
                                <div class="col-7 text-left"><p class="text-bold-700">Subtotal:</p></div>
                                <div class="col-5 text-right"><p class="text-bold-700"> ${{ parseFloat(preAppointmentResponse.subtotal_amount / 100).toFixed(2) }} </p> </div>
                            </div>

                            <br>
                            <div class="row" v-if="preAppointmentResponse.applied_discount.applied_status">
                                <div class="col-7 text-left">
                                    <p class="text-bold-400">Applied discount:</p>
                                </div>
                                <div class="col-5 text-right">
                                    <p>${{ parseFloat(preAppointmentResponse.applied_discount.amount /100 ).toFixed(2) }} </p>
                                </div>
                            </div>

                            <div class="row" v-if="preAppointmentResponse.parking_discount.applied_status">
                                <div class="col-7 text-left">
                                    <p class="text-bold-400">Parking Discount:</p>
                                </div>
                                <div class="col-5 text-right">
                                    <p>${{ parseFloat(preAppointmentResponse.parking_discount.amount /100 ).toFixed(2) }} </p>
                                </div>
                            </div>

                            <div class="row" v-if="preAppointmentResponse.coupon_discount.applied_status">
                                <div class="col-7 text-left">
                                    <p class="text-bold-400">Coupon Discount:</p>
                                </div>
                                <div class="col-5 text-right">
                                    <p class="">${{ parseFloat(preAppointmentResponse.coupon_discount.amount /100 ).toFixed(2) }}</p>
                                </div>
                            </div>
                            <div class="row" v-if="preAppointmentResponse.credited_payment_discount.applied_status">
                                <div class="col-7 text-left">
                                    <p class="text-bold-400">Credited Discount:</p>
                                </div>
                                <div class="col-5 text-right">
                                    <p class="">${{ parseFloat(preAppointmentResponse.credited_payment_discount.amount /100 ).toFixed(2) }}</p>
                                </div>
                            </div>

                            <div class="row" v-if="(parseFloat(preAppointmentResponse.total_discount_amount / 100).toFixed(2) > 0)">
                                <div class="col-7 text-left">
                                    <p class="text-bold-700">Total Discount:</p>
                                </div>
                                <div class="col-5 text-right">
                                    <p class="text-bold-700">${{ parseFloat(preAppointmentResponse.total_discount_amount / 100).toFixed(2) }} </p>
                                </div>
                            </div>
                            <div class="row" v-if="preAppointmentResponse.gst_charge.applied_status">
                                <div class="col-7 text-left text-bold-700"><P class="">GST:</P></div>
                                <div class="col-5 text-right text-bold-700"><P class=""> ${{ parseFloat(preAppointmentResponse.gst_charge.amount/100).toFixed(2) }} </P> </div>
                            </div>
                            <hr>
                            <div class="row" v-if="preAppointmentResponse.gst_charge.applied_status">
                                <div class="col-7 text-left text-bold-700"><P class="">Grand Total:</P></div>
                                <div class="col-5 text-right text-bold-700"><P class=""> ${{ parseFloat(preAppointmentResponse.grand_total / 100).toFixed(2)}} </P> </div>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer border-0 py-1">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">
                            <i class="bx bx-x d-block d-sm-none"></i>
                            <span class="d-none d-sm-block">Close</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="" data-toggle="modal" data-target="#costDetailsModal">
        </div>
    </template>
</AppLayout>
</template>

<script>
//internal components
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
// 3rd party packages
import VueMultiselect from 'vue-multiselect';
import {
    DatePicker
} from 'v-calendar';
import VueGoogleAutocomplete from 'vue-google-autocomplete';

// core packages
import {
    mapActions,
    mapGetters
} from 'vuex';

export default {
    components: {
        AppLayout,
        VueMultiselect,

        VueGoogleAutocomplete,
        DatePicker

    },
    name: "AppointmentCostEstimate",
    mixins: [ShowToastMessage, Loader, Authorization],
    data() {
        return {
            selectedService: {},
            servicePreferences: [{
                    name: 'Remote',
                    value: 1
                },
                {
                    name: 'On site',
                    value: 0
                }
            ],
            serviceTypes: [{
                    name: 'Home',
                    value: 0
                },
                {
                    name: 'Business',
                    value: 1
                }
            ],
          
          
            selectedState: {},
            selectedDate: "",
            selectedTime: {},
            selectedServicePreference: {},
            selectedServiceType: {},
            selectedParkingType:{},
            couponCode: '',
            street: '',
            suburb: '',
            post_code: '',
            country: '',
            customDiscount: false,
            discountTypes: [{
                    name: 'Fixed',
                    value: 1
                },
                {
                    name: 'Percentage',
                    value: 0
                }
            ],
            selectedDiscountType: {},
            customDiscountAmount: 0,

            preAppointmentResponse: {
                message: '',
                status: '',
                service_price: '',
                remaining_credited_payment: '',
                same_day_surcharge: {
                    applied_status: '',
                    amount: ''
                },
                timebase_surcharge: {
                    applied_status: '',
                    amount: '',
                },
                weekend_surcharge: {
                    applied_status: '',
                    amount: ''
                },
                distance_surcharge: {
                    applied_status: '',
                    message: '',
                    distance_value: '',
                    distance_value_in_km: '',
                    amount: '',
                },
                holiday_surcharge: {
                    applied_status: '',
                    amount: '',
                },
                total_surcharge_amount: '',
                subtotal_amount: '',
                applied_discount: {
                    applied_status: '',
                    amount: ''
                },
                coupon_discount: {
                    applied_status: '',
                    validation_status: '',
                    message: '',
                    apply_on: '',
                    amount: '',
                    coupon_id: '',
                    coupon_code: ''
                },

                credited_payment_discount: {
                    applied_status: '',
                    validation_status: '',
                    amount: '',
                    message: ''
                },
                total_discount_amount: '',
                gst_charge: {
                    applied_status: '',
                    amount: ''
                },
                grand_total: '',

                parking_discount: {
                    applied_status: '',
                    amount: '',

                },

            },

            errors: {},

        }
    },
    computed: {
        ...mapGetters({
            previousRoute: 'previousRoute',
            // service
            services: 'appService/services',
            // technician
            technicianAvailableTimeSlots: 'appTechnicians/technicianAvailableTimeSlots',
            serviceCategories: "appServiceCategories/serviceCategories",
            authUser: 'appAuthLogin/authUser',
        }),

        isCalculateButtonActive() {
            return (this.selectedServicePreference ?.name && this.selectedServiceType ?.name && this.street && this.suburb && this.state && this.post_code && this.selectedService ?.name && this.selectedTime ?.name && this.selectedDate );
        },

        parkingTypes() {
            return this.$store.getters['appSettings/settingDefaultParkingType'].value
        },

        state() {
            return this.$store.getters['appSettings/settingDefaultState'].value
        },
        redirectRouteName() {
            return this.can('service-view-any') ? 'appServiceList' : 'appServiceCreate';
        },
        getAvailableTimeSlotsWatcher() {
            const {
                selectedDate,
                selectedState,
                selectedServicePreference

            } = this;
            return {
                selectedDate,
                selectedState,
                selectedServicePreference

            }

        },

    },
    watch: {

        getAvailableTimeSlotsWatcher: {
          
            handler: async function (val) {
                const {
                    selectedDate,
                    selectedState,
                    selectedServicePreference
                } = val;

                if (selectedDate && (selectedState ?.value) && (selectedServicePreference ?.name)) {

                    this.getTechnicianTimeSlots();
                } else {
                    this.$store.commit('appTechnicians/SET_TECHNICIAN_AVAILABLE_TIME_SLOTS', {
                        status: false,
                        message: "Sorry, No slot is available.",
                        slots: []
                    });
                    this.selectedTime = {};
                }

            },
            deep: true
        },
    },
    methods: {
        ...mapActions({
            getServiceCategories: "appServiceCategories/getServiceCategories",
            postService: 'appService/postService',

            // appointment
            preStoreAppointmentData: 'appAppointments/preStoreAppointmentData',

            // setting
            getSettings: 'appSettings/getSettings',
            // service 
            getServices: 'appService/getServices',
            // technician
            postTechnicianAvailableTimeSlots: 'appTechnicians/postTechnicianAvailableTimeSlots',
        }),
        async getTechnicianTimeSlots() {
            this.selectedTime = {};
            this.loader(true);
            const data = {
                requested_date: this.selectedDate,
                state: this.selectedState.value,
                preference: this.selectedServicePreference.value
            }
            const response = await this.postTechnicianAvailableTimeSlots(data);
            this.loader(false);

            if (response.status === 200) {

                // this.loader(false);
                // if (this.technicianAvailableTimeSlots.status === true) {
                //     return true;
                // } else {
                //     return false;
                // }

            }
            // v-if="(selectedServicePreference?.value) && (selectedDate) && (technicianAvailableTimeSlots?.status === true)"
            if (this.technicianAvailableTimeSlots ?.message && this.technicianAvailableTimeSlots ?.status === false) {
                this.showToastMessage({
                    type: 'error',
                    message: this.technicianAvailableTimeSlots.message
                });
            }

        },
        async getSettingList() {
            this.loader(true);
            const response = await this.getSettings({
                type: ['default'],
                key: ['default_state','default_parking_type'],
            });
            this.loader(false);
            if (response.message) {
                this.showToastMessage(response.message);
            }
        },
        async getServiceList() {
            this.loader(true);
            const response = await this.getServices({
                status: 1
            });
            this.loader(false);
            if (response ?.message) {
                this.showToastMessage(response.message);
            }
        },
        googleMapApiResponseData: function (googleMapApiResponseData) {

            let streetNumber = googleMapApiResponseData.street_number !== undefined ? googleMapApiResponseData.street_number : '';
            let streetName = googleMapApiResponseData.route !== undefined ? googleMapApiResponseData.route : '';
            let suburbs = googleMapApiResponseData.locality !== undefined ? googleMapApiResponseData.locality : '';
            let state = googleMapApiResponseData.administrative_area_level_1 !== undefined ? googleMapApiResponseData.administrative_area_level_1 : '';
            let postCode = googleMapApiResponseData.postal_code !== undefined ? googleMapApiResponseData.postal_code : '';
            let country = googleMapApiResponseData.country !== undefined ? googleMapApiResponseData.country : '';

            this.street = streetNumber ? `${streetNumber} ${streetName}` : streetName;
            this.suburb = suburbs;
            this.state = state.toLowerCase();
            this.post_code = postCode;
            this.country = country;

            if (state) {
                let addressStateIndex = this.state.findIndex(item => item.value === state.toLowerCase());
                if (addressStateIndex !== -1) {
                    let addressState = this.state[addressStateIndex];
                    this.selectedState.value = addressState.value;
                    this.selectedState.name = addressState.name;
                }
            }

        },
        async preAppointment() {

            this.loader(true);
            const data = {
                preference: this.selectedServicePreference.value ?? "",
                type: this.selectedServiceType.value ?? "",
                street: this.street,
                suburb: this.suburb,
                post_code: this.post_code,
                country: this.country,
                state: this.selectedState.value,
                service_id: this.selectedService.id ?? '',
                date: this.selectedDate,
                time: this.selectedTime.value ?? '',
                
                client_panel: 1,
                coupon_code: this.couponCode ? this.couponCode : '',
                
                // applied_discount_amount: this.customDiscount ? (this.selectedDiscountType?.value === 1 ? (this.customDiscountAmount*100) : this.customDiscountAmount ) : 0,
                // applied_discount_type: this.customDiscount ? (this.selectedDiscountType ?.value ?? 1) : 1,

            };
            if ( this.customDiscount && (this.selectedDiscountType?.name) && this.customDiscountAmount) {
                data.applied_discount_amount = (this.selectedDiscountType.value === 1 ? (this.customDiscountAmount * 100) : this.customDiscountAmount);
                data.applied_discount_type = (this.selectedDiscountType.value);
            }
            if (this.selectedParkingType?.name) {
                data.parking_type = this.selectedParkingType?.value;
            }
          

            const response = await this.preStoreAppointmentData(data);

            if (response && response.status == 200) {
                this.loader(false);
                if (response.data.status == true) {

                    this.preAppointmentResponse.status = response.data.status;
                    this.preAppointmentResponse.message = response.data.message;
                    this.preAppointmentResponse.service_price = response.data.service_price;
                    this.preAppointmentResponse.remaining_credited_payment = response.data.remaining_credited_payment;

                    this.preAppointmentResponse.same_day_surcharge.applied_status = response.data.same_day_surcharge.applied_status;
                    this.preAppointmentResponse.same_day_surcharge.amount = response.data.same_day_surcharge.amount;

                    this.preAppointmentResponse.timebase_surcharge.applied_status = response.data.timebase_surcharge.applied_status;
                    this.preAppointmentResponse.timebase_surcharge.amount = response.data.timebase_surcharge.amount;

                    this.preAppointmentResponse.distance_surcharge.applied_status = response.data.distance_surcharge.applied_status;
                    this.preAppointmentResponse.distance_surcharge.distance_value = response.data.distance_surcharge.distance_value;
                    this.preAppointmentResponse.distance_surcharge.distance_value_in_km = response.data.distance_surcharge.distance_value_in_km;
                    this.preAppointmentResponse.distance_surcharge.amount = response.data.distance_surcharge.amount;

                    this.preAppointmentResponse.holiday_surcharge.applied_status = response.data.holiday_surcharge.applied_status;
                    this.preAppointmentResponse.holiday_surcharge.amount = response.data.holiday_surcharge.amount;

                    this.preAppointmentResponse.weekend_surcharge.amount = response.data.weekend_surcharge.amount;
                    this.preAppointmentResponse.weekend_surcharge.applied_status = response.data.weekend_surcharge.applied_status;

                    this.preAppointmentResponse.total_surcharge_amount = response.data.total_surcharge_amount;
                    this.preAppointmentResponse.subtotal_amount = response.data.subtotal_amount;

                    this.preAppointmentResponse.applied_discount.applied_status = response.data.applied_discount.applied_status;
                    this.preAppointmentResponse.applied_discount.amount = response.data.applied_discount.amount;

                    this.preAppointmentResponse.parking_discount.applied_status = response.data.parking_discount.applied_status;
                    this.preAppointmentResponse.parking_discount.amount = response.data.parking_discount.amount;

                    this.preAppointmentResponse.coupon_discount.amount = response.data.coupon_discount.amount;
                    this.preAppointmentResponse.coupon_discount.applied_status = response.data.coupon_discount.applied_status;
                    this.preAppointmentResponse.coupon_discount.apply_on = response.data.coupon_discount.apply_on;
                    this.preAppointmentResponse.coupon_discount.coupon_code = response.data.coupon_discount.coupon_code;
                    this.preAppointmentResponse.coupon_discount.coupon_id = response.data.coupon_discount.coupon_id;
                    this.preAppointmentResponse.coupon_discount.message = response.data.coupon_discount.message;
                    this.preAppointmentResponse.coupon_discount.validation_status = response.data.coupon_discount.validation_status;

                    this.preAppointmentResponse.credited_payment_discount.applied_status = response.data.credited_payment_discount.applied_status;
                    this.preAppointmentResponse.credited_payment_discount.validation_status = response.data.credited_payment_discount.validation_status;
                    this.preAppointmentResponse.credited_payment_discount.amount = response.data.credited_payment_discount.amount;
                    this.preAppointmentResponse.credited_payment_discount.message = response.data.credited_payment_discount.message;

                    this.preAppointmentResponse.total_discount_amount = response.data.total_discount_amount;

                    this.preAppointmentResponse.gst_charge.amount = response.data.gst_charge.amount;
                    this.preAppointmentResponse.gst_charge.applied_status = response.data.gst_charge.applied_status;

                    this.preAppointmentResponse.grand_total = response.data.grand_total;

                    document.querySelector('[data-target="#costDetailsModal"]').click();

                } else {
                    this.showToastMessage({
                        message: response.data.message,
                        type: "error"
                    });

                    //   document.querySelector('[data-target="#PresStoreAppointmentResponseModal"]').click();
                }

            } else {
                this.showToastMessage({
                    message: response.message,
                    type: "error"
                });

                this.loader(false);

            }

        },

    },

    async mounted() {

        await this.getSettingList();
        await this.getServiceList();

    },
}
</script>

<style scoped>
    p{
        padding-bottom: 0;
        margin-bottom: 0;
    }
.multiselect__tags:focus-within {

    /* border:1px solid red !important; */
    border-color: #5A8DEE;

}

.multiselect__tags {
    min-height: 0;
    max-height: 38px !important;
    border-color: #DFE3E7;
}

.ql-editor {
    min-height: 200px !important;
}

.generate-password-btn {
    color: #2c6de9;
}
</style>
